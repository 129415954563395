import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { NavermapsProvider } from 'react-naver-maps';
import NaverMapComponent from './components/naverMap';
import ReportFormComponent from './components/reportForm';
import BugReportFormComponent from './components/bugReportForm';


function App() {
  return (
    <Router>
      <div className="App" style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
        <NavermapsProvider
          ncpClientId={ '46tornai9v' } // 발급받은 Client ID
          error={<p>Maps Load Error</p>}
          loading={<p>Maps Loading...</p>}
        >
          <Routes>
            <Route path="/" element={<NaverMapComponent />} />
            <Route path="/report" element={<ReportFormComponent />} />
            <Route path="/bug" element={<BugReportFormComponent />} />
          </Routes>
        </NavermapsProvider>
      </div>
    </Router>
  );
}

export default App;