import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Container as MapDiv, NaverMap, Marker } from 'react-naver-maps';
import { useSpring, animated, config } from 'react-spring';
import { useSwipeable } from 'react-swipeable';
import axios from 'axios';

// 전역 스타일 정의
const globalStyles = `
  @font-face {
    font-family: 'BMHANNAPro';
    src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_seven@1.0/BMHANNAPro.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'BMHANNAAir';
    src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_four@1.0/BMHANNAAir.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow: hidden;
  }
  .header-title {
    font-size: 24px;
    white-space: nowrap;
  }
  .report-link {
    font-size: 16px;
    white-space: nowrap;
  }
  @media (max-width: 1080px) {
    .header-title {
      font-size: clamp(16px, 4vw, 24px);
    }
    .report-link {
      font-size: clamp(12px, 3vw, 16px);
    }
  }
`;

// 카테고리 Enum 정의
const CategoryEnum = {
  ALL: "전체",
  KOREAN: "한식",
  JAPANESE: "일식",
  CHINESE: "중식",
  WESTERN_FOOD: "양식",
  MEAT: "고기",
  FRIED_CHICKEN: "치킨",
  CHICKEN: "닭요리",
  FISH: "물고기",
  DRINK: "술집",
  CAFE: "카페"
};

const fetchRestaurants = async (category, maxRange) => {
    try {
        const params = {
            max_range: maxRange / 1000 // 미터를 킬로미터로 변환
        };
        
        if (category !== 'ALL') {
            params.category = category;
        }

        const response = await axios.get('https://api-winoreat.devgyurak.com/api/v1/restaurants/restaurants', { params });
        return response.data.results;
    } catch (error) {
        console.error('Error fetching restaurants:', error);
        return [];
    }
};

const formatDistanceForFilter = (distance) => {
    if (distance < 1000) {
        return distance + 'm';
    } else {
        return (distance / 1000).toFixed(1) + 'km';
    }
};

const formatDistance = (distance) => {
    // 소수점 첫째자리까지 반올림
    return distance.toFixed(1) + 'km';
};

const FilterComponent = ({ selectedCategory, onFilterChange, distance, onDistanceChange, isLoading }) => {
    const [localDistance, setLocalDistance] = useState(distance);
    const debounceTimer = useRef(null);

    const handleDistanceChange = (newDistance) => {
        setLocalDistance(newDistance);
        if (debounceTimer.current) {
            clearTimeout(debounceTimer.current);
        }
        debounceTimer.current = setTimeout(() => {
            onDistanceChange(newDistance);
        }, 500); // 500ms 후에 실제 변경 적용
    };

    return (
        <div style={{
            position: 'absolute',
            top: '10px',
            left: '10px',
            right: '10px',
            zIndex: 10,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '10px',
            padding: '10px',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            borderRadius: '8px'
        }}>
            <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap', justifyContent: 'center' }}>
                {Object.entries(CategoryEnum).map(([key, value]) => (
                    <button
                        key={key}
                        onClick={() => onFilterChange(key)}
                        style={{
                            padding: '5px 10px',
                            border: 'none',
                            borderRadius: '20px',
                            backgroundColor: key === selectedCategory ? '#007bff' : 'white',
                            color: key === selectedCategory ? 'white' : '#007bff',
                            cursor: isLoading ? 'not-allowed' : 'pointer',
                            fontFamily: 'BMHANNAAir, sans-serif',
                            fontSize: '14px',
                            transition: 'all 0.3s ease',
                            opacity: isLoading ? 0.5 : 1
                        }}
                        disabled={isLoading}
                    >
                        {value}
                    </button>
                ))}
            </div>
            <div style={{ width: '100%', maxWidth: '300px' }}>
                <input
                    type="range"
                    min="10"
                    max="20000"
                    step="10"
                    value={localDistance}
                    onChange={(e) => handleDistanceChange(Number(e.target.value))}
                    style={{ width: '100%', opacity: isLoading ? 0.5 : 1 }}
                    disabled={isLoading}
                />
                <div style={{ display: 'flex', justifyContent: 'space-between', fontFamily: 'BMHANNAAir, sans-serif', fontSize: '12px' }}>
                    <span>10m</span>
                    <span>{formatDistanceForFilter(localDistance)}</span>
                    <span>20km</span>
                </div>
            </div>
        </div>
    );
};

const StoreDetailView = ({ store, onClose, isVisible }) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const detailRef = useRef(null);
    const scrollContainerRef = useRef(null);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (!isVisible && detailRef.current) {
            detailRef.current.scrollTop = 0;
        }
    }, [isVisible]);

    const isWideScreen = windowWidth >= 1024;

    const animation = useSpring({
        transform: isVisible 
            ? 'translate(0%)' 
            : isWideScreen 
                ? 'translateX(-100%)' 
                : 'translateY(100%)',
        opacity: isVisible ? 1 : 0,
        config: { ...config.molasses, tension: 280, friction: 60 },
        onRest: () => {
            if (!isVisible) {
                onClose();
            }
        },
    });

    const detailStyle = {
        position: 'fixed',
        backgroundColor: 'white',
        padding: '20px',
        boxShadow: '0 -2px 10px rgba(0,0,0,0.1)',
        zIndex: 1000,
        overflowY: 'auto',
        fontFamily: 'BMHANNAAir, sans-serif',
        ...(isWideScreen
            ? { left: 0, top: 0, bottom: 0, width: '30%' }
            : { left: 0, right: 0, bottom: 0, height: '50vh' }),
    };

    const fullAddress = `${store.address} ${store.detail_address}`.trim();

    const getPickInfo = () => {
        if (store.players_pick && store.players_pick.trim().length > 0) {
            return `${store.players_pick} 선수가 고른 맛집!`;
        } else if (store.suggested_count && store.suggested_count > 0) {
            return `${store.suggested_count}명이 고른 맛집!`;
        }
        return null;
    };

    const pickInfo = getPickInfo();

    const handlers = useSwipeable({
        onSwipedLeft: () => {
            if (scrollContainerRef.current) {
                scrollContainerRef.current.scrollBy({ left: scrollContainerRef.current.offsetWidth, behavior: 'smooth' });
            }
        },
        onSwipedRight: () => {
            if (scrollContainerRef.current) {
                scrollContainerRef.current.scrollBy({ left: -scrollContainerRef.current.offsetWidth, behavior: 'smooth' });
            }
        },
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    });

    return (
        <>
            {isVisible && !isWideScreen && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: 999
                    }}
                    onClick={onClose}
                />
            )}
            <animated.div 
                ref={detailRef}
                style={{
                    ...detailStyle,
                    ...animation,
                }}
                onClick={(e) => e.stopPropagation()}
            >
                <h2 style={{ 
                    fontFamily: 'BMHANNAPro, sans-serif',
                    color: '#007bff',
                    borderBottom: '2px solid #007bff',
                    paddingBottom: '10px',
                    marginBottom: '10px'
                }}>{store.name}</h2>
                {pickInfo && (
                    <p style={{
                        fontFamily: 'BMHANNAPro, sans-serif',
                        color: '#28a745',
                        fontSize: '0.9em',
                        marginBottom: '20px',
                        textAlign: 'left'
                    }}>
                        {pickInfo}
                    </p>
                )}
                {store.image_urls && store.image_urls.length > 0 && (
                    <div style={{ 
                        marginBottom: '20px',
                        width: '100%',
                        height: '200px',
                        position: 'relative',
                        overflow: 'hidden'
                    }}>
                        <div 
                            {...handlers}
                            ref={scrollContainerRef}
                            style={{ 
                                display: 'flex',
                                overflowX: 'auto',
                                overflowY: 'hidden',
                                scrollSnapType: 'x mandatory',
                                WebkitOverflowScrolling: 'touch',
                                msOverflowStyle: 'none',
                                scrollbarWidth: 'none',
                                height: '100%',
                                width: '100%'
                            }}
                        >
                            <style>
                                {`
                                    div::-webkit-scrollbar {
                                        display: none;
                                    }
                                `}
                            </style>
                            {store.image_urls.map((image, index) => (
                                <div
                                    key={index}
                                    style={{
                                        flexShrink: 0,
                                        width: '100%',
                                        height: '100%',
                                        scrollSnapAlign: 'start',
                                        position: 'relative',
                                        overflow: 'hidden'
                                    }}
                                >
                                    <img 
                                        src={image} 
                                        alt={`${store.name} 이미지 ${index + 1}`} 
                                        style={{ 
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'cover',
                                            objectPosition: 'center'
                                        }}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                <div style={{ marginBottom: '15px' }}>
                    <p style={{ 
                        backgroundColor: '#f0f0f0',
                        padding: '10px',
                        borderRadius: '5px',
                        marginBottom: '10px',
                        whiteSpace: 'normal',
                        wordBreak: 'break-word'
                    }}>
                        <strong>주소:</strong> {fullAddress}
                    </p>
                </div>
                <p style={{ 
                    backgroundColor: '#fff0f0',
                    padding: '10px',
                    borderRadius: '5px',
                    marginBottom: '15px'
                }}>
                    라팍에서 {formatDistance(store.far_from_lions_park)} 만큼 떨어져 있어요!
                </p>
                <h3 style={{ 
                    fontFamily: 'BMHANNAPro, sans-serif',
                    color: '#28a745',
                    borderBottom: '2px solid #28a745',
                    paddingBottom: '10px',
                    marginBottom: '15px'
                }}>리뷰:</h3>
                <ul style={{ 
                    listStyleType: 'none',
                    padding: 0
                }}>
                    {store.review_posts.map((review, index) => (
                        <li key={index} style={{
                            backgroundColor: '#f8f9fa',
                            padding: '10px',
                            borderRadius: '5px',
                            marginBottom: '10px',
                            boxShadow: '0 1px 3px rgba(0,0,0,0.1)'
                        }}>{review}</li>
                    ))}
                </ul>
                {isWideScreen && (
                    <button
                        onClick={onClose}
                        style={{
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                            background: 'none',
                            border: 'none',
                            fontSize: '24px',
                            cursor: 'pointer',
                            color: '#007bff'
                        }}
                    >
                        ×
                    </button>
                )}
            </animated.div>
        </>
    );
};

// Footer 컴포넌트 수정
const Footer = () => {
    return (
      <footer style={{
        backgroundColor: '#f8f9fa',
        color: '#6c757d',
        padding: '10px',
        fontFamily: 'BMHANNAAir, sans-serif',
        fontSize: '14px',
        borderTop: '1px solid #dee2e6',
        textAlign: 'center'
      }}>
        <div>© 2024 라팍 맛집 지도. All rights reserved.</div>
        <div style={{
          marginTop: '5px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexWrap: 'wrap'
        }}>
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
            '@media (min-width: 768px)': {
              justifyContent: 'flex-end'
            }
          }}>
            <a href="mailto:contact@devgyurak.com" style={{
              color: '#007bff',
              textDecoration: 'none',
              margin: '0 5px'
            }}>
              개발자에게 연락하기!
            </a>
            <span style={{ margin: '0 5px', color: '#6c757d' }}>|</span>
            <a href="/bug" style={{
              color: '#007bff',
              textDecoration: 'none',
              margin: '0 5px'
            }}>
              버그 제보하기
            </a>
          </div>
        </div>
      </footer>
    );
   };
  
  const NaverMapComponent = () => {
      const [restaurants, setRestaurants] = useState([]);
      const [selectedRestaurant, setSelectedRestaurant] = useState(null);
      const [isDetailVisible, setIsDetailVisible] = useState(false);
      const [selectedCategory, setSelectedCategory] = useState('ALL');
      const [distance, setDistance] = useState(2000); // 기본값 2km
      const [isLoading, setIsLoading] = useState(false);
      const mapRef = useRef(null);
  
      const fetchData = useCallback(async () => {
          setIsLoading(true);
          const data = await fetchRestaurants(selectedCategory, distance);
          setRestaurants(data);
          setIsLoading(false);
      }, [selectedCategory, distance]);
  
      useEffect(() => {
          fetchData();
      }, [fetchData]);
  
      const adjustMapView = useCallback(() => {
          if (restaurants.length > 0 && mapRef.current) {
              const bounds = new window.naver.maps.LatLngBounds();
              restaurants.forEach(restaurant => {
                  bounds.extend(new window.naver.maps.LatLng(restaurant.latitude, restaurant.longitude));
              });
              
              const lionsStadium = new window.naver.maps.LatLng(35.841129, 128.6812364);
              bounds.extend(lionsStadium);
  
              mapRef.current.fitBounds(bounds, {
                  top: 50,
                  right: 50,
                  bottom: 50,
                  left: 50
              });
  
              // 최소 줌 레벨 설정 (전체 마커가 항상 보이도록)
              const zoom = mapRef.current.getZoom();
              const minZoom = 13;
              
              if (zoom < minZoom) {
                  mapRef.current.setZoom(minZoom);
              }
  
              mapRef.current.setCenter(lionsStadium);
          }
      }, [restaurants]);
  
      useEffect(() => {
          adjustMapView();
      }, [restaurants, adjustMapView]);
  
      useEffect(() => {
          const handleResize = () => {
              adjustMapView();
          };
  
          window.addEventListener('resize', handleResize);
          return () => {
              window.removeEventListener('resize', handleResize);
          };
      }, [adjustMapView]);
  
      const createMarkerIcon = useCallback(() => {
          return `
        <div style="position: relative;">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="30" viewBox="0 0 24 30">
            <path d="M12 0 C18.6 0 24 5.4 24 12 C24 21 12 30 12 30 C12 30 0 21 0 12 C0 5.4 5.4 0 12 0 Z" fill="red"/>
            <circle cx="12" cy="12" r="4" fill="white"/>
          </svg>
        </div>
      `;
      }, []);
  
      const handleMarkerClick = useCallback((restaurant) => {
          setSelectedRestaurant(restaurant);
          setIsDetailVisible(true);
      }, []);
  
      const handleCloseDetail = useCallback(() => {
          setIsDetailVisible(false);
      }, []);
  
      const handleFilterChange = useCallback((category) => {
          if (!isLoading) {
              setSelectedCategory(category);
          }
      }, [isLoading]);
  
      const handleDistanceChange = useCallback((newDistance) => {
          if (!isLoading) {
              setDistance(newDistance);
          }
      }, [isLoading]);
  
      return (
          <div style={{
              display: 'flex',
              flexDirection: 'column',
              minHeight: '100vh'
          }}>
              <style>{globalStyles}</style>
              <header style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '10px 20px',
                  backgroundColor: '#f8f9fa',
                  boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
              }}>
                  <div style={{ width: '100px' }}></div>
                  <h1 className="header-title" style={{
                      margin: 0,
                      color: '#333',
                      fontFamily: 'BMHANNAPro, sans-serif',
                      flex: 1,
                      textAlign: 'center'
                  }}>
                      💙 라팍 맛집 지도 💙
                  </h1>
                  <div style={{ width: '100px', textAlign: 'right' }}>
                      <a href="/report" className="report-link" style={{
                          textDecoration: 'none',
                          color: '#007bff',
                          fontFamily: 'BMHANNAAir, sans-serif',
                          fontWeight: 'bold'
                      }}>
                          맛집 제보하기
                      </a>
                  </div>
              </header>
              <div style={{
                  backgroundColor: '#f8f9fa',
                  padding: '10px',
                  textAlign: 'center',
                  fontFamily: 'BMHANNAAir, sans-serif',
                  fontSize: 'clamp(12px, 3vw, 16px)',
                  color: '#495057'
              }}>
                  카테고리와 최대 길이를 설정한 후, 지도를 줌 해보세요!
              </div>
              <div style={{
                  position: 'relative',
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column'
              }}>
                  <FilterComponent
                      selectedCategory={selectedCategory}
                      onFilterChange={handleFilterChange}
                      distance={distance}
                      onDistanceChange={handleDistanceChange}
                      isLoading={isLoading}
                  />
                  <div style={{ flex: 1, position: 'relative' }}>
                      <MapDiv style={{ width: '100%', height: '100%' }}>
                          <NaverMap
                              ref={mapRef}
                              defaultCenter={{ lat: 35.841129, lng: 128.6812364 }}
                              defaultZoom={15}
                              zoomControl={false}
                              zoomControlOptions={{
                                  position: 'TOP_RIGHT'
                              }}
                          >
                              {restaurants.map(restaurant => (
                                  <Marker
                                      key={restaurant.id}
                                      position={{ lat: restaurant.latitude, lng: restaurant.longitude }}
                                      onClick={() => handleMarkerClick(restaurant)}
                                      icon={{
                                          content: createMarkerIcon(),
                                          anchor: { x: 12, y: 30 },
                                      }}
                                  />
                              ))}
                          </NaverMap>
                      </MapDiv>
                  </div>
                  {selectedRestaurant && (
                      <StoreDetailView
                          store={selectedRestaurant}
                          onClose={handleCloseDetail}
                          isVisible={isDetailVisible}
                      />
                  )}
              </div>
              <Footer />
          </div>
      );
  };
  
  export default NaverMapComponent;