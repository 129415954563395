import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Search, ChevronDown, X } from 'lucide-react';

// 전역 스타일 정의
const globalStyles = `
  @font-face {
    font-family: 'BMHANNAPro';
    src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_seven@1.0/BMHANNAPro.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'BMHANNAAir';
    src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_four@1.0/BMHANNAAir.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: 'BMHANNAAir', sans-serif;
    background-color: #f8f9fa;
  }
  .header-title {
    font-size: 24px;
    white-space: nowrap;
  }
  .back-to-map {
    font-size: 16px;
    white-space: nowrap;
  }
  @media (max-width: 1080px) {
    .header-title {
      font-size: clamp(16px, 4vw, 24px);
    }
    .back-to-map {
      font-size: clamp(12px, 3vw, 16px);
    }
  }
`;

// 카테고리 Enum 정의
const CategoryEnum = {
  KOREAN: "한식",
  JAPANESE: "일식",
  CHINESE: "중식",
  WESTERN_FOOD: "양식",
  MEAT: "고기",
  FRIED_CHICKEN: "치킨",
  CHICKEN: "닭요리",
  FISH: "물고기",
  DRINK: "술집",
  CAFE: "카페"
};

// Footer 컴포넌트
const Footer = () => {
  return (
    <footer style={{
      backgroundColor: '#f8f9fa',
      color: '#6c757d',
      padding: '10px',
      fontFamily: 'BMHANNAAir, sans-serif',
      fontSize: '14px',
      borderTop: '1px solid #dee2e6',
      textAlign: 'center'
    }}>
      <div>© 2024 라팍 맛집 지도. All rights reserved.</div>
      <div style={{
        marginTop: '5px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap'
      }}>
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexWrap: 'wrap',
          '@media (min-width: 768px)': {
            justifyContent: 'flex-end'
          }
        }}>
          <a href="mailto:contact@devgyurak.com" style={{
            color: '#007bff',
            textDecoration: 'none',
            margin: '0 5px'
          }}>
            개발자에게 연락하기!
          </a>
          <span style={{ margin: '0 5px', color: '#6c757d' }}>|</span>
          <a href="/bug" style={{
            color: '#007bff',
            textDecoration: 'none',
            margin: '0 5px'
          }}>
            버그 제보하기
          </a>
        </div>
      </div>
    </footer>
  );
 };

const ReportFormComponent = () => {
  const navigate = useNavigate();
  const [restaurantName, setRestaurantName] = useState('');
  const [roadAddress, setRoadAddress] = useState('');
  const [category, setCategory] = useState('');
  const [review, setReview] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitMessage, setSubmitMessage] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [isRestaurantDropdownOpen, setIsRestaurantDropdownOpen] = useState(false);
  const [isCategoryDropdownOpen, setIsCategoryDropdownOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isAddressSelected, setIsAddressSelected] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const searchInputRef = useRef(null);
  const categoryInputRef = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return; // 중복 제출 방지
    setIsSubmitting(true);
    setSubmitMessage('');
    setErrorMessage('');

    try {
      const response = await axios.post('https://api-winoreat.devgyurak.com/api/v1/restaurants/restaurant', {
        name: restaurantName,
        address: roadAddress,
        category: category,
        review: review || null
      });
      
      const count = response.data.count;
      let message = '';
      message = `🎉 등록에 성공했습니다!\n 현재 ${count}번 등록된 가게입니다.`;
      
      setSubmitMessage(message);
      setIsPopupOpen(true);
    } catch (error) {
      let errorMsg = '알 수 없는 에러가 발생했습니다.';
      if (error.response && error.response.status >= 400) {
        if (error.response.data && error.response.data[0]) {
          errorMsg = error.response.data[0];
        } else if (error.response.data && error.response.data.detail) {
          errorMsg = error.response.data.detail;
        }
      }
      setErrorMessage(errorMsg);
      setIsPopupOpen(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSearch = async () => {
    if (!restaurantName) return;
    setIsSearching(true);
    setErrorMessage('');
    try {
      const response = await axios.get(`https://api-winoreat.devgyurak.com/api/v1/restaurants/search-restaurants?name=${encodeURIComponent(restaurantName)}`);
      setSearchResults(response.data || []);
      setIsRestaurantDropdownOpen(true);
    } catch (error) {
      let errorMsg = '알 수 없는 에러가 발생했습니다.';
      if (error.response && error.response.status >= 400) {
        if (error.response.data && error.response.data[0]) {
          errorMsg = error.response.data[0];
        } else if (error.response.data && error.response.data.detail) {
          errorMsg = error.response.data.detail;
        }
      }
      setErrorMessage(errorMsg);
      setIsPopupOpen(true);
    } finally {
      setIsSearching(false);
    }
  };

  const handleRestaurantSelect = (selectedRestaurant) => {
    setRestaurantName(selectedRestaurant.name);
    setRoadAddress(selectedRestaurant.road_address);
    setIsRestaurantDropdownOpen(false);
    setIsAddressSelected(true);
  };

  const handleRestaurantNameFocus = () => {
    setRestaurantName('');
    setRoadAddress('');
    setIsRestaurantDropdownOpen(false);
    setIsAddressSelected(false);
  };

  const handleCategorySelect = (selectedCategory) => {
    setCategory(selectedCategory);
    setIsCategoryDropdownOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchInputRef.current && !searchInputRef.current.contains(event.target)) {
        setIsRestaurantDropdownOpen(false);
      }
      if (categoryInputRef.current && !categoryInputRef.current.contains(event.target)) {
        setIsCategoryDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const dropdownStyle = {
    position: 'absolute',
    top: '100%',
    left: 0,
    right: 0,
    backgroundColor: 'white',
    border: '1px solid #ddd',
    borderRadius: '0 0 4px 4px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    zIndex: 1000,
    maxHeight: '200px',
    overflowY: 'auto'
  };

  const dropdownItemStyle = {
    padding: '10px',
    cursor: 'pointer',
    borderBottom: '1px solid #eee',
    transition: 'background-color 0.2s',
    ':hover': {
      backgroundColor: '#f0f0f0'
    }
  };

  const PopupComponent = ({ message, onClose }) => (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 2000,
    }}>
      <div style={{
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        maxWidth: '80%',
        width: '300px',
        fontFamily: 'BMHANNAAir, sans-serif'
      }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
          <h3 style={{ margin: 0, fontFamily: 'BMHANNAPro, sans-serif' }}>알림</h3>
          <button onClick={onClose} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
            <X size={20} />
          </button>
        </div>
        <p style={{ margin: 0, whiteSpace: 'pre-line' }}>{message}</p>
      </div>
    </div>
  );

  return (
    <div style={{ 
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh'
      }}>
        <style>{globalStyles}</style>
        <style>{`
          input:invalid,
          textarea:invalid {
            box-shadow: none;
          }
        `}</style>
        <header style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '10px 20px',
          backgroundColor: '#f8f9fa',
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
        }}>
          <div style={{ width: '100px' }}></div>
          <h1 className="header-title" style={{
            margin: 0,
            color: '#333',
            fontFamily: 'BMHANNAPro, sans-serif',
            flex: 1,
            textAlign: 'center'
          }}>
            💙 라팍 맛집 지도 💙
          </h1>
          <div style={{ width: '100px', textAlign: 'right' }}>
            <Link to="/" className="back-to-map" style={{
              textDecoration: 'none',
              color: '#007bff',
              fontFamily: 'BMHANNAAir, sans-serif',
              fontWeight: 'bold'
            }}>
              지도로 돌아가기
            </Link>
          </div>
        </header>
        <main style={{ 
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          padding: '20px',
          boxSizing: 'border-box',
          overflowY: 'auto'
        }}>
          <form onSubmit={handleSubmit} style={{
            width: '100%',
            maxWidth: '800px',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'white',
            padding: '30px',
            borderRadius: '8px',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            boxSizing: 'border-box',
            fontFamily: 'BMHANNAAir, sans-serif',
            marginBottom: '20px'
          }}>
            <div style={{ marginBottom: '20px', position: 'relative' }} ref={searchInputRef}>
              <label htmlFor="restaurantName" style={{ 
                display: 'block',
                marginBottom: '5px',
                fontFamily: 'BMHANNAPro, sans-serif',
                color: '#333'
              }}>
                가게 이름
              </label>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  type="text"
                  id="restaurantName"
                  value={restaurantName}
                  onChange={(e) => setRestaurantName(e.target.value)}
                  onFocus={handleRestaurantNameFocus}
                  required
                  placeholder="제보하려는 식당을 검색해주세요."
                  style={{
                    width: '100%',
                    padding: '10px',
                    border: '1px solid #ddd',
                    borderRadius: '4px 0 0 4px',
                    fontSize: '16px',
                    boxSizing: 'border-box',
                    fontFamily: 'BMHANNAAir, sans-serif'
                  }}
                />
                <button
                  type="button"
                  onClick={handleSearch}
                  disabled={isSearching}
                  style={{
                    padding: '10px',
                    backgroundColor: '#007bff',
                    color: 'white',
                    border: 'none',
                    borderRadius: '0 4px 4px 0',
                    cursor: 'pointer'
                  }}
                >
                  <Search size={20} />
                </button>
              </div>
              <p style={{
                margin: '5px 0 0',
                fontSize: '14px',
                color: '#555',
                fontStyle: 'italic'
              }}>
                Tip: 프렌차이즈의 경우 동 + 이름을 검색해주세요. (ex: 진천동 봉자막창)
              </p>
              {isRestaurantDropdownOpen && searchResults.length > 0 && (
                <div style={dropdownStyle}>
                  {searchResults.map((result, index) => (
                    <div
                      key={index}
                      onClick={() => handleRestaurantSelect(result)}
                      style={dropdownItemStyle}
                    >
                      <div style={{ fontWeight: 'bold' }}>{result.name}</div>
                      <div style={{ fontSize: '0.8em', color: '#666' }}>{result.road_address}</div>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div style={{ marginBottom: '20px' }}>
              <label htmlFor="roadAddress" style={{ 
                display: 'block',
                marginBottom: '5px',
                fontFamily: 'BMHANNAPro, sans-serif',
                color: '#333'
              }}>
                도로명 주소
              </label>
              <input
                type="text"
                id="roadAddress"
                value={roadAddress}
                onChange={(e) => setRoadAddress(e.target.value)}
                required
                placeholder="도로명 주소"
                disabled={isAddressSelected}
                style={{
                  width: '100%',
                  padding: '10px',
                  border: '1px solid #ddd',
                  borderRadius: '4px',
                  fontSize: '16px',
                  boxSizing: 'border-box',
                  fontFamily: 'BMHANNAAir, sans-serif',
                  backgroundColor: isAddressSelected ? '#f0f0f0' : 'white'
                }}
              />
            </div>
            <div style={{ marginBottom: '20px', position: 'relative' }} ref={categoryInputRef}>
              <label htmlFor="category" style={{ 
                display: 'block',
                marginBottom: '5px',
                fontFamily: 'BMHANNAPro, sans-serif',
                color: '#333'
              }}>
                카테고리
              </label>
              <div
                onClick={() => setIsCategoryDropdownOpen(!isCategoryDropdownOpen)}
                style={{
                  width: '100%',
                  padding: '10px',
                  border: '1px solid #ddd',
                  borderRadius: '4px',
                  fontSize: '16px',
                  backgroundColor: 'white',
                  boxSizing: 'border-box',
                  cursor: 'pointer',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  fontFamily: 'BMHANNAAir, sans-serif'
                }}
              >
                {category ? CategoryEnum[category] : "선택해주세요"}
                <ChevronDown size={20} />
              </div>
              {isCategoryDropdownOpen && (
                <div style={dropdownStyle}>
                  {Object.entries(CategoryEnum).map(([key, value]) => (
                    <div
                      key={key}
                      onClick={() => handleCategorySelect(key)}
                      style={dropdownItemStyle}
                    >
                      {value}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div style={{ marginBottom: '20px' }}>
              <label htmlFor="review" style={{ 
                display: 'block',
                marginBottom: '5px',
                fontFamily: 'BMHANNAPro, sans-serif',
                color: '#333'
              }}>
                리뷰 (선택사항)
              </label>
              <textarea
                id="review"
                value={review}
                onChange={(e) => setReview(e.target.value)}
                style={{
                  width: '100%',
                  height: '150px',
                  padding: '10px',
                  border: '1px solid #ddd',
                  borderRadius: '4px',
                  fontSize: '16px',
                  resize: 'vertical',
                  boxSizing: 'border-box',
                  fontFamily: 'BMHANNAAir, sans-serif'
                }}
              />
            </div>
            <button type="submit" disabled={isSubmitting} style={{
              width: '100%',
              padding: '10px',
              backgroundColor: '#007bff',
              color: 'white',
              border: 'none',
              borderRadius: '4px',
              fontSize: '16px',
              cursor: 'pointer',
              opacity: isSubmitting ? 0.7 : 1,
              fontFamily: 'BMHANNAPro, sans-serif'
            }}>
              {isSubmitting ? '제출 중...' : '제보하기'}
            </button>
          </form>
        </main>
        <Footer />
        {isPopupOpen && (
          <PopupComponent 
            message={errorMessage || submitMessage} 
            onClose={() => {
              setIsPopupOpen(false);
              setErrorMessage('');
              setSubmitMessage('');
              if (submitMessage) {
                navigate('/');
              }
            }} 
          />
        )}
    </div>
  );
};

export default ReportFormComponent;