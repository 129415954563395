import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ChevronDown, X } from 'lucide-react';

const globalStyles = `
  @font-face {
    font-family: 'BMHANNAPro';
    src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_seven@1.0/BMHANNAPro.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'BMHANNAAir';
    src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_four@1.0/BMHANNAAir.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow: hidden;
  }
  .header-title {
    font-size: 24px;
    white-space: nowrap;
  }
  .report-link {
    font-size: 16px;
    white-space: nowrap;
  }
  @media (max-width: 1080px) {
    .header-title {
      font-size: clamp(16px, 4vw, 24px);
    }
    .report-link {
      font-size: clamp(12px, 3vw, 16px);
    }
  }
`;

// 버그 유형 Enum 정의
const BugTypeEnum = {
  RESTAURANT_NOT_FOUND: "식당을 찾을 수 없어요.",
  WRONG_RESTAURANT_IMAGE: "식당 이미지들이 이상해요.",
  ADVERTISEMENT_DOUBT: "광고가 의심돼요.",
  NOT_RESTAURANT_PLACE: "이 장소는 식당이 아니에요.",
  SERVICE_NOT_WORKED: "서비스가 정상 동작하지 않아요."
};

const BugReportFormComponent = () => {
  const navigate = useNavigate();
  const [bugType, setBugType] = useState('');
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitMessage, setSubmitMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isBugTypeDropdownOpen, setIsBugTypeDropdownOpen] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const bugTypeInputRef = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;
    setIsSubmitting(true);
    setSubmitMessage('');
    setErrorMessage('');

    try {
      await axios.post('https://api-winoreat.devgyurak.com/api/v1/bugs/bugs', {
        bug_type: bugType,
        title: title,
        description: content,
        email: email || null
      });
      
      setSubmitMessage('버그 제보가 성공적으로 접수되었습니다.\n 감사합니다!');
      setIsPopupOpen(true);
    } catch (error) {
      let errorMsg = '알 수 없는 에러가 발생했습니다.';
      if (error.response && error.response.status >= 400) {
        if (error.response.data && error.response.data[0]) {
          errorMsg = error.response.data[0];
        } else if (error.response.data && error.response.data.detail) {
          errorMsg = error.response.data.detail;
        }
      }
      setErrorMessage(errorMsg);
      setIsPopupOpen(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleBugTypeSelect = (selectedBugType) => {
    setBugType(selectedBugType);
    setIsBugTypeDropdownOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (bugTypeInputRef.current && !bugTypeInputRef.current.contains(event.target)) {
        setIsBugTypeDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const dropdownStyle = {
    position: 'absolute',
    top: '100%',
    left: 0,
    right: 0,
    backgroundColor: 'white',
    border: '1px solid #ddd',
    borderRadius: '0 0 4px 4px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    zIndex: 1000,
    maxHeight: '200px',
    overflowY: 'auto'
  };

  const dropdownItemStyle = {
    padding: '10px',
    cursor: 'pointer',
    borderBottom: '1px solid #eee',
    transition: 'background-color 0.2s',
    ':hover': {
      backgroundColor: '#f0f0f0'
    }
  };

  const PopupComponent = ({ message, onClose }) => (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 2000,
    }}>
      <div style={{
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        maxWidth: '80%',
        width: '300px',
        fontFamily: 'BMHANNAAir, sans-serif'
      }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
          <h3 style={{ margin: 0, fontFamily: 'BMHANNAPro, sans-serif' }}>알림</h3>
          <button onClick={onClose} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
            <X size={20} />
          </button>
        </div>
        <p style={{ margin: 0, whiteSpace: 'pre-line' }}>{message}</p>
      </div>
    </div>
  );

  const Footer = () => {
    return (
      <footer style={{
        backgroundColor: '#f8f9fa',
        color: '#6c757d',
        padding: '10px',
        fontFamily: 'BMHANNAAir, sans-serif',
        fontSize: '14px',
        borderTop: '1px solid #dee2e6',
        textAlign: 'center'
      }}>
        <div>© 2024 라팍 맛집 지도. All rights reserved.</div>
        <div style={{
          marginTop: '5px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexWrap: 'wrap'
        }}>
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
            '@media (min-width: 768px)': {
              justifyContent: 'flex-end'
            }
          }}>
            <a href="mailto:contact@devgyurak.com" style={{
              color: '#007bff',
              textDecoration: 'none',
              margin: '0 5px'
            }}>
              개발자에게 연락하기!
            </a>
            <span style={{ margin: '0 5px', color: '#6c757d' }}>|</span>
            <a href="/bug" style={{
              color: '#007bff',
              textDecoration: 'none',
              margin: '0 5px'
            }}>
              버그 제보하기
            </a>
          </div>
        </div>
      </footer>
    );
   };

  return (
    <>
      <style>{globalStyles}</style>
      <div style={{ 
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh'
      }}>
        <header style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '10px 20px',
          backgroundColor: '#f8f9fa',
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
        }}>
          <div style={{ width: '100px' }}></div>
          <h1 className="header-title" style={{
            margin: 0,
            color: '#333',
            fontFamily: 'BMHANNAPro, sans-serif',
            flex: 1,
            textAlign: 'center'
          }}>
            💙 라팍 맛집 지도 💙
          </h1>
          <div style={{ width: '100px', textAlign: 'right' }}>
            <Link to="/" className="back-to-map report-link" style={{
              textDecoration: 'none',
              color: '#007bff',
              fontFamily: 'BMHANNAAir, sans-serif',
              fontWeight: 'bold'
            }}>
              지도로 돌아가기
            </Link>
          </div>
        </header>
        <main style={{ 
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          padding: '20px',
          boxSizing: 'border-box',
          overflowY: 'auto'
        }}>
          <form onSubmit={handleSubmit} style={{
            width: '100%',
            maxWidth: '800px',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'white',
            padding: '30px',
            borderRadius: '8px',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            boxSizing: 'border-box',
            fontFamily: 'BMHANNAAir, sans-serif',
            marginBottom: '20px'
          }}>
            <div style={{ marginBottom: '20px', position: 'relative' }} ref={bugTypeInputRef}>
              <label htmlFor="bugType" style={{ 
                display: 'block',
                marginBottom: '5px',
                fontFamily: 'BMHANNAPro, sans-serif',
                color: '#333'
              }}>
                버그 유형
              </label>
              <div
                onClick={() => setIsBugTypeDropdownOpen(!isBugTypeDropdownOpen)}
                style={{
                  width: '100%',
                  padding: '10px',
                  border: '1px solid #ddd',
                  borderRadius: '4px',
                  fontSize: '16px',
                  backgroundColor: 'white',
                  boxSizing: 'border-box',
                  cursor: 'pointer',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  fontFamily: 'BMHANNAAir, sans-serif'
                }}
              >
                {bugType ? BugTypeEnum[bugType] : "선택해주세요"}
                <ChevronDown size={20} />
              </div>
              {isBugTypeDropdownOpen && (
                <div style={dropdownStyle}>
                  {Object.entries(BugTypeEnum).map(([key, value]) => (
                    <div
                      key={key}
                      onClick={() => handleBugTypeSelect(key)}
                      style={dropdownItemStyle}
                    >
                      {value}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div style={{ marginBottom: '20px' }}>
              <label htmlFor="title" style={{ 
                display: 'block',
                marginBottom: '5px',
                fontFamily: 'BMHANNAPro, sans-serif',
                color: '#333'
              }}>
                제목
              </label>
              <input
                type="text"
                id="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
                placeholder="버그 제목을 입력해주세요"
                style={{
                  width: '100%',
                  padding: '10px',
                  border: '1px solid #ddd',
                  borderRadius: '4px',
                  fontSize: '16px',
                  boxSizing: 'border-box',
                  fontFamily: 'BMHANNAAir, sans-serif'
                }}
              />
            </div>
            <div style={{ marginBottom: '20px' }}>
              <label htmlFor="content" style={{ 
                display: 'block',
                marginBottom: '5px',
                fontFamily: 'BMHANNAPro, sans-serif',
                color: '#333'
              }}>
                내용
              </label>
              <textarea
                id="content"
                value={content}
                onChange={(e) => setContent(e.target.value)}
                required
                placeholder="버그에 대해 자세히 설명해주세요"
                style={{
                  width: '100%',
                  height: '150px',
                  padding: '10px',
                  border: '1px solid #ddd',
                  borderRadius: '4px',
                  fontSize: '16px',
                  resize: 'vertical',
                  boxSizing: 'border-box',
                  fontFamily: 'BMHANNAAir, sans-serif'
                }}
              />
            </div>
            <div style={{ marginBottom: '20px' }}>
              <label htmlFor="email" style={{ 
                display: 'block',
                marginBottom: '5px',
                fontFamily: 'BMHANNAPro, sans-serif',
                color: '#333'
              }}>
                이메일 (선택사항)
              </label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="입력하시면 처리 내용을 메일로 받을 수 있습니다."
                style={{
                  width: '100%',
                  padding: '10px',
                  border: '1px solid #ddd',
                  borderRadius: '4px',
                  fontSize: '16px',
                  boxSizing: 'border-box',
                  fontFamily: 'BMHANNAAir, sans-serif'
                }}
              />
            </div>
            <button type="submit" disabled={isSubmitting} style={{
              width: '100%',
              padding: '10px',
              backgroundColor: '#007bff',
              color: 'white',
              border: 'none',
              borderRadius: '4px',
              fontSize: '16px',
              cursor: 'pointer',
              opacity: isSubmitting ? 0.7 : 1,
              fontFamily: 'BMHANNAPro, sans-serif'
            }}>
              {isSubmitting ? '제출 중...' : '버그 제보하기'}
            </button>
          </form>
        </main>
        <Footer />
        {isPopupOpen && (
          <PopupComponent 
            message={errorMessage || submitMessage} 
            onClose={() => {
              setIsPopupOpen(false);
              setErrorMessage('');
              setSubmitMessage('');
              if (submitMessage) {
                navigate('/');
              }
            }} 
          />
        )}
      </div>
    </>
  );
};

export default BugReportFormComponent;